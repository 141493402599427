.contact {
    display: "flex";
    margin: 0.6rem auto;
    align-items: center;
    justify-content: center;
    color: $dark-blue;

    .emailLink {
        color: $cta-color;
        
        &:hover {
            color: $dark-blue;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.1rem;
            margin: auto 0;

        }
    }

    .map-container {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}