@import 'config';
@import 'navBar';
@import 'footer';
@import 'home';
@import 'projects';
@import 'skills';
@import 'about';
@import 'contact';
@import 'notFound';



body {
    background-color: $light-grey;
    background-image: radial-gradient(transparent 65%, rgba(black, 0.3));
    color: $dark-blue;
}

.main-content {
    min-height: 94vh;
    display:flex;

    .cta-button {
        color: $cta-color;
        background-color: transparent;
        border: 2px solid $cta-color;
        border-radius: 10px;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: $dark-blue;
            border-color: $dark-blue;
            background-color: $frosted-color;
            backdrop-filter: $frosted-filter;
        }
    }
    
  }

.reveal {
  position: relative;
  display: inline-block;
  animation-name: appear;
  animation-duration: 1000ms;
  animation-fill-mode: both;
  animation-delay: 200ms;
  animation-timing-function: cubic-bezier(0.4,0,0.2,1);
  &:after {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 100%; height: 100%;
    background-color: $light-grey;
    animation-name: reveal;
    animation-duration: inherit;
    animation-fill-mode: inherit;
    animation-delay: inherit;
    animation-timing-function: inherit;
  }
  & + & {
    animation-delay: 1000ms;
  }
  
  @keyframes reveal {
    from { transform: scaleX(0); transform-origin: left; }
    40% { transform: scaleX(1); transform-origin: left; }
    60% { transform: scaleX(1); transform-origin: right; }
    to { transform: scaleX(0); transform-origin: right; }
  }
  
  @keyframes appear {
    from { color: transparent; }
    40% { color: transparent; }
    60% { color: inherit; }
    to { color: inherit; }
  }
}