// resets
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  #particle-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

// variables
$light-grey: #e0e0e0;
$dark-blue: #00224D;
// $cta-color : #6699cc;
// $cta-color : #6b2400;
$cta-color : #ff6600;

$diamond-grid-gap: 0.2rem;
$diamond-grid-offset: calc(50% - $diamond-grid-gap);

// frosted glass effect
  $frosted-color: #ffffff26;
  $frosted-filter: blur(5px);
