.door {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: 6px;
    width: 200px;
    height: 50px;
    box-shadow: inset -7px 0px 12px -8px #0000004d,
      inset 7px 0px 12px -8px #0000004d;
    background: transparent ;
    transition: all .4s ease-in-out;

  
    &:hover {
      background: #fff;
  
      .slide {
        box-shadow: 0px 0px 10px -2px #00000066;
        transform: scale(1.08);
        background-color: #fff;
      }
  
      .door-top {
        top: -50%;
        
      }
  
      .door-bottom {
        top: 100%;
      }
    }
  
    .resume-btn {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 #000000;
    background-color: transparent ;
    margin-left: -50px;
    position: absolute;
    margin-top: -20px;
    left: 53%;
    top: 50%;
    font-size: 0.9rem;
    font-weight: 700;
    color: $cta-color;
    border: 2px solid $cta-color;
    transition: all .2s ease-in-out;

    &:hover {
      color: $dark-blue;
      border-color: $dark-blue;
    }
  }
  
  .door-top {
    top: 0%;
    left: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  
    &:before {
      top: 5px;
    }
  }
  
  .door-bottom {
    top: 50%;
    left: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  
    &:before {
      top: -20px;
    }
  }
  
  .slide {
    transition: top 400ms, box-shadow 200ms, transform 300ms;
    transition-timing-function: ease-in-out;
    background-color: $light-grey;
    position: absolute;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    height: 50%;
  
    &:before {
      position: absolute;
      left: 45%;
      margin-top: 8px;
      display: block;
      content: url("../assets/bxs-download.svg");
    }
  }
  

}
.footer-link {
  color:  $cta-color;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  border-radius: 15px;
  transition: background 0.2s ease-in-out;
  display: flex;
  align-items: center;


  &:hover{
      background-color: #F7EEDD20;
      color: #fff;
  }
}
