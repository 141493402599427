.about {
    padding: 0.5rem 1rem;
    width: 94vw;
    margin: 0 auto;

    .grid-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
    "description description education "
    "strong-skills avatar-image soft-skills" 
    "certificates experience experience";
    column-gap: 1rem;
    row-gap: 2rem;
    width: 100%;
    padding: 1rem;

    .area {
        background-color: $frosted-color;
        backdrop-filter: $frosted-filter;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;


        &:nth-child(1){
            grid-area: description;
        }
        &:nth-child(2){
            grid-area: education;
            gap: 0.5rem;
        }
        &:nth-child(3){
            grid-area: strong-skills;
        }
        &:nth-child(4){
            grid-area: avatar-image;
            border-radius: 50%;
            width: 320px;
            height: 320px;
            margin: auto;
            background-image: url("../assets/profilePicture.jpg");
            background-position: center;
            background-size: cover;
        }
        &:nth-child(5){
            grid-area: soft-skills;
        }
        &:nth-child(6){
            grid-area: certificates;
        }
        &:nth-child(7){
            grid-area: experience;
        }
    }

}

@media screen and ((max-width: 1024px) and (min-width: 768px)) {
        .grid-wrapper {
            .area{
                &:nth-child(4){
                    width: 240px;
                    height: 240px;
                }
            }
        }
    
}

@media screen and (max-width: 768px) {
    .grid-wrapper {
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "avatar-image"
            "description"
            "education" 
            "strong-skills"
            "soft-skills" 
            "certificates"
            "experience";
    }
}
}
