.navLinkMobile,
.navLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
    color: inherit;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.4rem 0.8rem;  
    background-color: transparent;
    text-align: start;
}
.navLink {
    border-radius: 20px;
    box-shadow: 0 0 0 0 #000000;
	  transform: scale(1);
    transition: all 0.5s ease;
	@mixin pulse-animation {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
    
      &:hover {
          @include pulse-animation;
          background-color: #F7EEDD20;
      }
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 #F7EEDD20;
          }
          50% {
            box-shadow: 0 0 0 10px #F7EEDD30;
          }
          100% {
            box-shadow: 0 0 0 0 #F7EEDD20;
          }
      }

      #menu-appbar {
        ul {
            background-color: $dark-blue;
            color: #fff;
        }
    }

    .navLinkMobile {
      border-radius: 10px;
      &:hover{
        background-color: #F7EEDD20;
      }
    }
    .typewriter {
      width: 36ch;
      white-space: nowrap;
      overflow: hidden;
      
      &.animate {
        animation: typing 2s steps(50);
      }
    
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 36ch;
        }
      }
    }
    